<template>
    <div  class="w-full space-y-3">
        <div class="space-y-8">
            <div class="space-y-1">
                <div class="relative">
                    <input ref="password" id="password" type="password" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="auth.password" v-bind:class="{'border-red-500 border-2 focus:border-red-500 border-2 bg-red-100': authFailed }" @keyup.enter="signIn()" />
                    <label for="password" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': authFailed }">{{ $t('auth.form.password') }}</label>
                </div>
                <div class="text-sm flex items-center space-x-1 text-red-500" v-if="authFailed" >
                    <i class="fi fi-rr-exclamation flex items-center"></i>
                    <span>{{ $t('auth.form.password_not_valid') }}</span>
                </div>
            </div>

            <button v-if="!loading" class="bg-primary w-full rounded-lg text-white font-semibold py-3 px-5 hover:shadow-xl" @click="signIn()">{{ $t('auth.form.log_in') }}</button>
            <button v-else class="bg-gray-300 w-full rounded-lg text-white font-semibold py-3 px-5">
                <loading color="text-white" />
            </button>

            <div class="flex justify-between">
                <button class="font-semibold" @click="step = 'forgotPassword'">{{ $t('auth.form.forgot_password') }}</button>
                <button class="font-semibold" @click="step = 'initial'">{{ $t('auth.form.new_here') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import { useApi } from '../../lib/api';
    import { useAuth } from '../../lib/auth'

    export default {
	    props: [],
        components: {
        },
        watch: {
            'auth.password'(value) {
                this.authFailed = false
            }
        },
	    setup(props) {
            const { post, data, loading } = useApi('user/sign-in')
            const { redirectTo } = useAuth()
            const auth = inject('auth')
            const step = inject('step')
            const authFailed = ref(false)

            const signIn = (() => {
                if(auth.value.email && auth.value.password) {
                    post({
                        email: auth.value.email,
                        password: auth.value.password
                    }).then(() => {
                        if(data.value.status) {
                            window.location.replace(redirectTo.value)

                            return
                        }

                        authFailed.value = true
                    }).catch(() => {
                        authFailed.value = true
                    })
                }
            })

            return {
                auth,
                step,
                signIn,
                loading,
                authFailed
            }
        },
        mounted() {
            this.$refs.password.focus()
        }
    }
</script>
