<template>
    <div>
        <a :href="listing.url" class="block space-y-2" @mouseover="displayPhotosControls = true" @mouseleave="displayPhotosControls = false">
            <div class="rounded-lg overflow-hidden relative h-56">
                <div class="absolute top-0 p-3 flex w-full items-center z-10" v-bind:class="{'justify-between': listingIsNew || listing.status.name != 'available', 'justify-end': !listingIsNew || listing.status.name != 'available'}">

                    <div v-if="listingIsNew && listing.status.name == 'available'" class="bg-primary text-white p-1 rounded text-xs shadow-xl">{{ $t('listing.block.new_listing') }}</div>
                    <div v-if="listing.status.name != 'available'" class="p-1 rounded text-xs shadow-xl text-white" :style="`background-color:${ listing.status.color }`">{{ $t(`statuses.${ listing.status.name }`) }}</div>

                    <toggle-listing-favourite v-if="user" ref="toggleListingFavourite" :listing_id="listing.id">
                        <template v-slot:is-not-saved>
                            <button class="text-white text-2xl hover:text-red-500"><i class="fi fi-rr-heart"></i></button>
                        </template>

                        <template v-slot:is-saved>
                            <button class="text-2xl text-red-500"><i class="fi fi-rr-heart"></i></button>
                        </template>
                    </toggle-listing-favourite>
                </div>

                <div class="absolute w-full bottom-0 h-1/4 z-10">
                    <div class="w-full h-full opacity-80 bottom-0  bg-gradient-to-t from-black to-transparent p-3"></div>
                    <div class="font-bold text-white text-lg absolute bottom-3 left-3">{{ listing.price?.display_price ?? '-' }}</div>
                </div>

                <div v-if="listing.photos.length > 0" class="w-full h-full">
                    <Transition enter-from-class="opacity-0"
                                    enter-to-class="opacity-100"
                                    enter-active-class="transform transition ease-out duration-300 delay-200"
                                    leave-active-class="transform transition ease-in duration-300"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0" appear>
                        <div class="absolute w-full h-full z-10" v-if="displayPhotosControls">
                            <button class="absolute bg-white w-8 h-8 rounded-full shadow-lg flex items-center justify-center opacity-85 hover:opacity-100 left-3 top-1/2 -translate-y-1/2" @click.prevent="prevPhoto" v-if="activeImage > 0">
                                <i class="fi fi-rr-angle-small-left flex items-center"></i>
                            </button>
                            <button class="absolute bg-white w-8 h-8 rounded-full shadow-lg flex items-center justify-center opacity-85 hover:opacity-100 right-3 top-1/2 -translate-y-1/2" @click.prevent="nextPhoto" v-if="activeImage < listing.photos.length - 1">
                                <i class="fi fi-rr-angle-small-right flex items-center"></i>
                            </button>

                            <div class="absolute left-1/2 -translate-x-1/2 bottom-3 overflow-hidden" v-bind:style="{'width': `50px`}">
                                <ul class="flex space-x-1">
                                    <li ref="photoIndicatorEl" v-for="x in listing.photos.length" class="w-1.5 h-1.5 rounded-full bg-white flex-none transition ease-in-out duration-300" v-bind:class="{'opacity-55': (x - 1) != activeImage }" v-bind:style="{'transform': `translate(${ translatePhotoIndicatorX }px)`}"></li>
                                </ul>
                            </div>
                        </div>
                    </Transition>

                    <div class="flex w-full h-full" ref="photoEl">
                        <img v-for="photo in listing.photos" :src="photo.image_url" class="w-full h-full object-cover flex-none transition ease-in-out duration-300" v-bind:style="{'transform': `translate(${ activeImage * (-1 * photoWidth) }px)`}" />
                    </div>
                </div>
                <img v-else src="/images/blank_image.png" class="w-full h-full object-cover" />
            </div>

            <div>
                <h3 v-if="listing.address.country" class="flex items-center space-x-2">
                    <img :src="`/images/flags/${ listing.address.country.icon }.svg`" class="w-4" />
                    <span class="font-semibold text-gray-600 text-sm">{{ $t(`countries.${ listing.address.country.name }`) }}</span>
                </h3>
                <h2 class="font-semibold h-12 overflow-hidden">{{ listing.title }}</h2>
            </div>

            <ul class="text-sm flex space-x-8">
                <li class="text-center" v-if="listing.features.areas.living_area> 0">
                    <i class="fi fi-rr-draw-square block"></i>
                    <span>{{ listing.features.areas.living_area }} m²</span>
                </li>
                <li class="text-center" v-if="listing.features.areas.plot_size > 0">
                    <i class="fi fi-rr-border-all block"></i>
                    <span>{{ listing.features.areas.plot_size }} m²</span>
                </li>
                <li class="text-center" v-if="listing.features.layout.numbers_of_bedrooms > 0">
                    <i class="fi fi-rr-bed-alt block"></i>
                    <span>{{ listing.features.layout.numbers_of_bedrooms }}</span>
                </li>
            </ul>
        </a>
    </div>
</template>


<script>
	import { ref, computed } from 'vue'
    import dayjs from 'dayjs'
    import { useHelpers } from '../lib/helpers'
    import ToggleListingFavourite from './ToggleListingFavourite.vue'
    import { useUser } from '../lib/user'

	export default {
	    props: ['listing'],
        components: {
            ToggleListingFavourite
        },
        watch: {
            photoIndicatorEl(value) {
                if(value && value.length) {
                    this.photoIndicatorWidth = value[0].getBoundingClientRect().width + 4
                }
            },
            photoEl(value) {
                if(value) {
                    this.photoWidth = value.getBoundingClientRect().width
                }
            }
        },
	    setup(props) {
            const listingIsNew = ref(dayjs().diff(props.listing.created_at, 'day') < 5)
            const { user } = useUser()
            const activeImage = ref(0)
            const photoWidth = ref(0)
            const photoIndicatorWidth = ref(0)
            const displayPhotosControls = ref(false)
            const photoIndicatorEl = ref(null)
            const photoEl = ref(null)

            const { price, slug } = useHelpers()

            const prevPhoto = () => {
                activeImage.value--
            }

            const nextPhoto = () => {
                activeImage.value++
            }

            const translatePhotoIndicatorX = computed(() => {
                if(props.listing.photos.length > 5) {
                    if((activeImage.value + 2) >= props.listing.photos.length) {
                        return ((props.listing.photos.length - 5) * photoIndicatorWidth.value) * -1
                    }

                    if((activeImage.value + 1) > 3) {
                        return ((activeImage.value - 2) * photoIndicatorWidth.value) * -1
                    }
                }

                return 0
            })

            return {
                user,
                dayjs,
                listingIsNew,
                price,
                slug,
                prevPhoto,
                nextPhoto,
                activeImage,
                photoWidth,
                photoIndicatorWidth,
                translatePhotoIndicatorX,
                displayPhotosControls,
                photoIndicatorEl,
                photoEl
            }
		}
	}
</script>
