<template>
    <div class="space-y-1">
        <div class="relative">
            <input id="password" type="password" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="password" @focus="showPasswordHelper = true" @blur="showPasswordHelper = false" v-bind:class="{'border-red-500 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('password') || !validator.isEmpty(password ?? '') && !passwordIsOkay }" />
            <label for="password" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('password') || !validator.isEmpty(password ?? '') && !passwordIsOkay }">{{ $t('auth.form.password') }}</label>
        </div>
        <div v-if="hasValidationErrors('password') || showPasswordHelper" class="text-xs text-gray-500">
            <ul class="font-semibold space-y-1">
                <li v-if="passwordIsOkay" class="text-green-600 flex items-center space-x-1">
                    <i class="fi fi-rr-check-circle flex items-center"></i>
                    <span>Password strength: strong</span>
                </li>
                <li v-else class="text-red-600 flex items-center space-x-1">
                    <i class="fi fi-rr-cross-circle flex items-center"></i>
                    <span>Password strength: weak</span>
                </li>
                <li v-if="validator.contains(`${ $props.firstName }`, password, { ignoreCase: true }) || validator.contains(`${ $props.lastName }`, password, { ignoreCase: true }) || validator.contains(`${$props.email }`, password, { ignoreCase: true })" class="text-red-600 flex items-center space-x-1">
                    <i class="fi fi-rr-cross-circle flex items-center"></i>
                    <span>Can't contain your name or email address</span>
                </li>
                <li v-if="!validator.isLength(password ?? '', {min: 8})" class="text-red-600 flex items-center space-x-1">
                    <i class="fi fi-rr-cross-circle flex items-center"></i>
                    <span>At least 8 characters</span>
                </li>
                <li v-if="!validator.matches(password ?? '', /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{}|;:',.<>?/~]).*$/i)" class="text-red-600 flex items-center space-x-1">
                    <i class="fi fi-rr-cross-circle flex items-center"></i>
                    <span>Contains a number or symbol</span>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
import { ref, computed } from 'vue'
import validator from 'validator'
import { useFormValidator } from '../lib/formValidator';

export default {
    props: ['modelValue', 'firstName', 'lastName', 'email'],
    watch: {
        password(value) {
            this.$emit('update:modelValue', value)
        }
    },
    setup(props) {
        const { validationErrors, hasValidationErrors, resetErrors } = useFormValidator()
        const showPasswordHelper = ref(false)
        const password = ref(props.modelValue)

        const passwordIsOkay = computed(() => {
            return (!validator.contains(`${ props.firstName }`, password.value ?? '', { ignoreCase: true }) ||
                        !validator.contains(`${ props.lastName }`, password.value ?? '', { ignoreCase: true }) ||
                        !validator.contains(`${ props.email }`, password.value ?? '', { ignoreCase: true })) &&
                        validator.isLength(password.value ?? '', {min: 8}) &&
                        validator.matches(password.value ?? '', /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{}|;:',.<>?/~]).*$/i)
        })

        return {
            showPasswordHelper,
            validator,
            hasValidationErrors,
            password,
            passwordIsOkay
        }
    }
}

</script>
