<template>
    <div  class="w-full space-y-3">

        <button class="font-semibold" @click="step = 'initial'">{{ $t('auth.form.already_registered') }}</button>

        <div class="space-y-8">

            <div class="space-y-1">
                <div class="border border-gray-300 rounded-lg overflow-hidden divide-y">
                    <div class="relative">
                        <input id="first_name" ref="firstName" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " v-model="auth.first_name" v-bind:class="{'border-red-500 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('firstName') }" />
                        <label for="first_name" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('firstName') }">{{ $t('auth.form.first_name') }}</label>
                    </div>

                    <div class="relative">
                        <input id="last_name" type="text" class="block px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer rounded-lg" placeholder=" " v-model="auth.last_name" v-bind:class="{'border-red-500 border-2 focus:border-red-500 bg-red-100': hasValidationErrors('lastName') }" />
                        <label for="last_name" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('lastName') }">{{ $t('auth.form.last_name') }}</label>
                    </div>
                </div>

                <div v-if="hasValidationErrors('firstName') || hasValidationErrors('lastName')" class="text-sm flex items-center space-x-1 text-red-500">
                    <i class="fi fi-rr-exclamation flex items-center"></i>
                    <span>{{ $t('auth.form.name_not_valid') }}</span>
                </div>
            </div>

            <div class="space-y-1">
                <div class="relative">
                    <input id="dob" type="date" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="auth.dob" v-bind:class="{'border-red-500 border-2 focus:border-red-500 bg-red-100': hasValidationErrors('dob') || !validator.isEmpty(auth.dob ?? '') && !validator.isDate(auth.dob) }" />
                    <label for="dob" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('dob') || !validator.isEmpty(auth.dob ?? '') && !validator.isDate(auth.dob) }">{{ $t('auth.form.birthdate') }}</label>
                </div>
                <div class="text-xs text-gray-500" v-if="!hasValidationErrors('dob') && validator.isEmpty(auth.dob ?? '') || (validator.isDate(auth.dob) && dayjs().diff(dayjs(auth.dob), 'year') > 18)">
                    {{ $t('auth.form.you_need_to_be_at_least_eighteen') }}
                </div>
                <div class="text-sm flex items-center space-x-1 text-red-500" v-if="!validator.isEmpty(auth.dob ?? '') && !validator.isDate(auth.dob)" >
                    <i class="fi fi-rr-exclamation flex items-center"></i>
                    <span>{{ $t('auth.form.date_not_valid') }}</span>
                </div>
                <div class="text-sm flex items-center space-x-1 text-red-500" v-if="validator.isDate(auth.dob) && dayjs().diff(dayjs(auth.dob), 'year') < 18" >
                    <i class="fi fi-rr-exclamation flex items-center"></i>
                    <span>{{ $t('auth.form.you_must_to_be_at_least_eighteen') }}</span>
                </div>
                <div class="text-sm flex items-center space-x-1 text-red-500" v-if="hasValidationErrors('dob')" >
                    <i class="fi fi-rr-exclamation flex items-center"></i>
                    <span>{{ $t('auth.form.date_of_birth_is_required') }}</span>
                </div>
            </div>

            <div class="space-y-1">
                <div class="relative">
                    <input id="email" type="text" class="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-gray-900 bg-white border border-gray-300 appearance-none focus:border-2 focus:border-gray-800 focus:outline-none focus:ring-0 peer" placeholder=" " v-model="auth.email" v-bind:class="{'border-red-500 border-2 focus:border-red-500 border-2 bg-red-100': hasValidationErrors('email') || !validator.isEmpty(auth.email ?? '') && !validator.isEmail(auth.email ?? '') }" />
                    <label for="email" class="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto" v-bind:class="{'text-red-500 peer-focus:text-red-500': hasValidationErrors('email') || !validator.isEmpty(auth.email ?? '') && !validator.isEmail(auth.email ?? '') }">{{ $t('auth.form.email') }}</label>
                </div>
                <div class="text-sm flex items-center space-x-1 text-red-500" v-if="hasValidationErrors('email')" >
                    <i class="fi fi-rr-exclamation flex items-center"></i>
                    <span>{{ $t('auth.form.enter_valid_email') }}</span>
                </div>
            </div>

            <CreateAccountPassword ref="passwordInput" :firstName="auth.first_name" :lastName="auth.last_name" :email="auth.email" @keyup.enter="signUp()" v-model="auth.password" />
        </div>

        <div class="text-sm" v-html="$t('auth.form.sign_up_tos')"></div>

        <button v-if="!loading" class="bg-primary w-full rounded-lg text-white font-semibold py-3 px-5 hover:shadow-xl" @click="signUp()">{{ $t('auth.form.agree_and_continue') }}</button>
        <button v-else class="bg-gray-300 w-full rounded-lg text-white font-semibold py-3 px-5">
            <loading color="text-white" />
        </button>

        <div class="py-5">
            <hr />
        </div>

        <div class="space-y-5 text-sm text-gray-600">
            <div>
                {{ $t('auth.form.sign_up_disclamer') }}
            </div>
            <label class="flex items-center space-x-5 cursor-pointer">
                <input type="checkbox" class="w-5 h-5 accent-gray-800" v-model="auth.optin" :true-value="false" :false-value="true" />
                <span>{{ $t('auth.form.dont_want_to_receive_marketing_messages') }}</span>
            </label>
        </div>

    </div>
</template>


<script>
import { ref, inject, computed } from 'vue'
import validator from 'validator';
import dayjs from 'dayjs'
import { useFormValidator } from '../../lib/formValidator';
import { useApi } from '../../lib/api'
import { useAuth } from '../../lib/auth'
import CreateAccountPassword from '../CreateAccountPassword.vue'

export default {
    components: {
        CreateAccountPassword
    },
    watch: {
        'auth.first_name'(value) {
            if(value) {
                this.validationErrors = this.validationErrors.filter(e => e !== 'firstName')
            }
        },
        'auth.last_name'(value) {
            if(value) {
                this.validationErrors = this.validationErrors.filter(e => e !== 'lastName')
            }
        },
        'auth.dob'(value) {
            if(value) {
                this.validationErrors = this.validationErrors.filter(e => e !== 'dob')
            }
        },
        'auth.email'(value) {
            if(value) {
                this.validationErrors = this.validationErrors.filter(e => e !== 'email')
            }
        },
        'auth.password'(value) {
            if(value) {
                this.validationErrors = this.validationErrors.filter(e => e !== 'password')
            }
        }
    },
    setup() {
        const { validationErrors, hasValidationErrors, resetErrors } = useFormValidator()
        const { redirectTo } = useAuth()

        const auth = inject('auth')
        const step = inject('step')
        const passwordInput = ref(null)
        const loading = ref(false)

        const signUp = () => {
            resetErrors()

            if(validator.isEmpty(auth.value.first_name ?? '')) {
                validationErrors.value.push('firstName')
            }

            if(validator.isEmpty(auth.value.last_name ?? '')) {
                validationErrors.value.push('lastName')
            }

            if(validator.isEmpty(auth.value.dob ?? '') || (validator.isDate(auth.value.dob) && dayjs().diff(dayjs(auth.value.dob), 'year') < 18)) {
                validationErrors.value.push('dob')
            }

            if(!validator.isEmail(auth.value.email ?? '')) {
                validationErrors.value.push('email')
            }

            if(!passwordInput.value.passwordIsOkay) {
                validationErrors.value.push('password')
            }

            if(validationErrors.value.length === 0) {
                loading.value = true

                const { post, data } = useApi('user/sign-up')

                post(auth.value).then(() => {
                    loading.value = false

                    if(data.value.status) {
                        window.location.replace(redirectTo.value)
                    }
                }).catch(() => {
                    loading.value = false
                })
            }
        }

        return {
            auth,
            signUp,
            validator,
            dayjs,
            loading,
            validationErrors,
            hasValidationErrors,
            step,
            passwordInput
        }
    },
    mounted() {
        this.$refs.firstName.focus()
    }
}

</script>
